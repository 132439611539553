import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import {
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  CountsSection,
  Faq
} from "../commonComponents"

import {
  IntegrationDarkSection
} from "../integrationsUtils"

import { Layout } from "../components/Layout"


export default function ProactiveCustomerSupport() {
  return (
    <Layout
      title="AI Ticket Triaging: Streamlined & Efficient Ticket Triage Solutions"
      keywords="AI ticket triaging, Proactive Customer Support, ticket triaging, automate support operations"
      description="Utilize AI ticket triaging to optimize support workflows. With our ticket triaging, you can categorize tickets quickly & accurately. Get ticket resolutions, faster."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/proactive-customer-support/"
        />
      </Helmet>
      <main id="main">
        {/*  ======= Hero Section =======  */}
        <section
          id="hero-new-design"
          className="d-flex align-items-center section-padding-left section-padding-right"
          style={{ paddingTop: "5%", paddingBottom: "5%" }}
        >
          <div className="row" style={{ width: "100%", paddingTop: "2%" }}>
            <div className="col-md-6">
              <h1 className="title-support-new-design">
                Proactive Support with AI-powered <span style={{ color: "#EF5DA8", fontWeight: 600 }}>ticket triaging</span>
              </h1>
              <div className="description-support-new-design">
                <span className="web-view-new-design-display">
                  Discover root cause, assign, and triage tickets <br/>
                  automatically. Correlate customer support tickets<br/>
                  with engineering issues to contextualize user issues <br />
                  and prevent costly engineering escalations.
                </span>
                <span className="mobile-view-new-design-display">
                  Discover root cause, assign, and triage tickets <br/>
                  automatically. Correlate customer support tickets<br/>
                  with engineering issues to contextualize user issues <br />
                  and prevent costly engineering escalations.
                </span>
                <br/>
              </div>
              <div className="description-button-support-new-design">
                <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="/img/aboutSection.webp"
                alt="support agent"
                style={{ width: "88%" }}
              />
            </div>
          </div>
        </section>
        {/*  End Hero  */}
{/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try out{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}

        {/*  ======= Hero Section =======  */}
        <section
          className="section-padding-y section-padding-left d-flex align-items-center section-padding-right"
          style={{ height: "100%" }}
        >
          <div
            className="row web-view-new-design-display"
            style={{ height: "100%" }}
          >
            <div className="col-md-4">
              <div
                className="proactive-support-customer-box-discover"
                style={{
                  background:
                    "linear-gradient(91.64deg, #2BCDFF 0.51%, #22D6BA 71.55%)",
                }}
              >
                <span className="proactive-support-customer-box-text">
                  FOR CUSTOMER SUPPORT TEAMS
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <img
                src="/img/proactiveSupport2.webp"
                style={{
                  width: "88%",
                  margin: "2% 0 0 10%",
                }}
                alt="about"
              />
              <div
                style={{
                  position: "absolute",
                  float: "left",
                  top: "5%",
                  width: "45%",
                  left: "1.2%",
                }}
              >
                <h2 className="proactive-support-tittle">
                  Real-time correlation of tickets, alerts & bugs for faster resolution
                </h2>
                <div className="proactive-support-description">
                  Support teams benefit from AI-powered ticket triaging,
                  which improves response times and prevents costly escalations
                  by intelligently assigning tickets. Identify when a product release or
                  bug is the source of a customer issue.
                </div>
                <br/><br/>
              </div>
            </div>

            <div
              className="row"
              style={{
                width: "100%",
                height: "100%",
                marginRight: "5%",
                marginLeft: "0",
                marginTop: "-1%",
                zIndex: -1,
              }}
            >
              <div className="col-md-4">
                <div className="proactive-support-checkbox checkbox-more-padding">
                  <img src="/img/checkCircle.png" alt="checkCircle" />
                  <h3 className="text">
                    Better collaboration and
                    <br />
                    partnership with
                    <br />
                    engineering teams
                  </h3>
                </div>
              </div>
              <div className="col-md-4">
                <div className="proactive-support-checkbox checkbox-more-padding">
                  <img src="/img/checkCircle.png" alt="checkCircle" />
                  <h3 className="text">
                    Workflow automations to
                    <br />
                    save manual labor and
                    <br />
                    avoid mistakes
                  </h3>
                </div>
              </div>
              <div className="col-md-4">
                <div className="proactive-support-checkbox checkbox-more-padding">
                  <img src="/img/checkCircle.png" alt="checkCircle" />
                  <h3 className="text">
                    Instant problem discovery
                    <br />
                    to help you resolve
                    <br />
                    support tickets faster
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row mobile-view-new-design-display"
            style={{ height: "100%" }}
          >
            <div className="col-md-6">
              <div
                className="proactive-support-customer-box-discover"
                style={{
                  background:
                    "linear-gradient(91.64deg, #2BCDFF 0.51%, #22D6BA 71.55%)",
                }}
              >
                <span className="proactive-support-customer-box-text">
                  FOR CUSTOMER SUPPORT TEAMS
                </span>
              </div>
              <h2
                className="proactive-support-tittle"
                style={{ color: "#3E4450" }}
              >
                Real-time correlation of tickets, alerts & bugs for faster resolution
              </h2>
              <div
                className="proactive-support-description"
                style={{ color: "#828282" }}
              >
                IrisAgent can identify when a product release or
                <br />
                feature regression/bug is the source of a customer
                <br />
                issue. Resolve support tickets faster and get alerts
                <br />
                about product bugs.
              </div>
              <div style={{ display: "flex", marginTop: "3%" }}>
                <DemoButton />
              </div>
              <div className="col-md-6">
                <div style={{ marginBottom: "4%", marginTop: "2%" }}>
                  <img
                    src="/img/proactiveSuport2Mobile.webp"
                    style={{
                      width: "80%",
                      marginLeft: "-5%",
                    }}
                    alt="support agent"
                  />
                  <div className="container-checkbox-more-padding-mobile">
                    <div className="proactive-support-checkbox">
                      <img
                        src="/img/checkCircleMobile.png"
                        alt="checkCircle"
                        style={{ width: 36, marginLeft: "-9%" }}
                      />
                      <div className="text">
                        Better collaboration and
                        <br />
                        partnership with engineering
                        <br />
                        teams
                      </div>
                    </div>
                    <div
                      className="proactive-support-checkbox"
                      style={{ marginTop: "8%" }}
                    >
                      <img
                        src="/img/checkCircleMobile.png"
                        alt="checkCircle"
                        style={{ width: 36, marginLeft: "-9%" }}
                      />
                      <div className="text">
                        Workflow automations to
                        <br />
                        save manual labor and avoid
                        <br />
                        mistakes
                      </div>
                    </div>
                    <div
                      className="proactive-support-checkbox"
                      style={{ marginTop: "8%" }}
                    >
                      <img
                        src="/img/checkCircleMobile.png"
                        alt="checkCircle"
                        style={{ width: 36, marginLeft: "-9%" }}
                      />
                      <div className="text">
                        Instant problem discovery to
                        <br />
                        help you resolve support <br />
                        tickets faster
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End Hero  */}

        {/*  ======= Hero Section =======  */}

        <section
          className="section-padding-y section-padding-left"
          style={{ background: "#F9F9F9", height: "auto" }}
        >
          <div className="row" style={{ height: "100%", marginBottom: "3%" }}>
            <div className="col-md-6 web-view-new-design-display">
              <img
                src="/img/proactiveSupport3.webp"
                alt="support agent"
                style={{ width: "90%", marginTop: "13%" }}
              />
            </div>
            <div className="col-md-6">
              <div
                className="proactive-support-customer-box-trending"
                style={{
                  margin: "5% 0",
                  background:
                    "linear-gradient(91.64deg, #2BCDFF 0.51%, #22D6BA 71.55%)",
                }}
              >
                <span className="proactive-support-customer-box-text">
                  FOR CUSTOMER SUPPORT TEAMS
                </span>
              </div>
              <div>
                <h2 className="proactive-support-tittle">
                  Discover trending
                  <br />
                  customer issues with
                  <br />
                  anomaly detection
                </h2>
                <div className="proactive-support-description">
                  Our Early Warning Engine detects product and
                  <br />
                  customer experience issues based on anomalies and
                  <br />
                  trends in customer support agents and users' data.
                </div>
                <div style={{ display: "flex", marginTop: "3%" }}>
                  <GetStartedButton />
                </div>
              </div>
            </div>
            <div className="col-md-6 mobile-view-new-design-display">
              <img
                src="/img/proactiveSupport3.webp"
                alt="support agent"
                style={{ width: "100%", marginLeft: "-10%" }}
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              height: "100%",
              marginRight: "5%",
            }}
          >
            <div className="col-md-4" style={{ marginBottom: "3%" }}>
              <div className="proactive-support-checkbox" style={{ margin: 0 }}>
                <img
                  src="/img/checkCircle.png"
                  alt="checkCircle"
                  className="proactive-support-checkbox-img"
                />
                <h3 className="web-view-new-design-display text">
                  Find and Resolve impactful
                  <br />
                  issues that are affecting
                  <br />
                  customers at scale
                </h3>
                <h3 className="mobile-view-new-design-display text">
                  Find and Resolve impactful issues
                  <br />
                  that are affecting customers at scale
                </h3>
              </div>
            </div>
            <div className="col-md-4" style={{ marginBottom: "3%" }}>
              <div className="proactive-support-checkbox" style={{ margin: 0 }}>
                <img
                  src="/img/checkCircle.png"
                  alt="checkCircle"
                  className="proactive-support-checkbox-img"
                />
                <h3 className="web-view-new-design-display text">
                  Create automated
                  <br />
                  workflows for real-time
                  <br />
                  alerts
                </h3>
                <h3 className="mobile-view-new-design-display text">
                  Create automated workflows for
                  <br />
                  real-time alerts
                </h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="proactive-support-checkbox" style={{ margin: 0 }}>
                <img
                  src="/img/checkCircle.png"
                  alt="checkCircle"
                  className="proactive-support-checkbox-img"
                />
                <h3 className="web-view-new-design-display text">
                  Collaborate with internal
                  <br />
                  product and engineering
                  <br />
                  teams
                </h3>
                <h3 className="mobile-view-new-design-display text">
                  Collaborate with internal product
                  <br />
                  and engineering teams
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/*  End Hero  */}

        {/*  ======= PRODUCT AND ENGINEERING TEAMS Section =======  */}

        <section className="section-padding-y section-padding-left d-flex align-items-center">
          <div className="row" style={{ height: "100%", marginRight: "5%" }}>
            <div className="col-md-6">
              <div>
                <div
                  className="proactive-support-customer-box"
                  style={{
                    margin: "5% 0",
                    background:
                      "linear-gradient(91.36deg, #5D5FEF 0.42%, #407BFF 59.55%)",
                  }}
                >
                  <span className="proactive-support-customer-box-text">
                    FOR PRODUCT AND ENGINEERING TEAMS
                  </span>
                </div>
                <div>
                  <h2 className="proactive-support-tittle">
                    Know the revenue and customer sentiment impact of product bugs
                  </h2>
                  <ul
                    className="proactive-support-engineering-teams"
                    style={{ width: "max-content" }}
                  >
                    <li style={{ paddingLeft: "10px" }}>
                      Prioritize product bugs with a significant
                      <br />
                      impact on customer support
                    </li>
                    <li style={{ paddingLeft: "10px" }}>
                      Know which customers are impacted by
                      <br />
                      key bugs
                    </li>
                    <li style={{ paddingLeft: "10px" }}>
                      Bugs and tickets are synchronized as
                      <br />
                      they update
                    </li>
                  </ul>
                </div>
                <div style={{ display: "flex", marginTop: "3%" }}>
                  <DemoButton />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="/img/proactiveSupport4.webp"
                alt="support agent"
                className="proactive-product-img"
              />
            </div>
          </div>
        </section>
        {/*  End PRODUCT AND ENGINEERING TEAMS  */}

        {/* <!-- ======= Counts Section ======= --> */}
        {/* <section
          id="counts"
          className="section-padding-left d-flex align-items-center"
          style={{ backgroundColor: "#000000" }}
        >
          <div
            className="row"
            style={{
              marginTop: "9%",
              marginRight: "7%",
              marginBottom: "8%",
              width: "100%",
            }}
          >
            <div className="col-md-10">
              <div
                className="agent-assistant-free-trial-title web-view-new-design-display"
                style={{ color: "#fff" }}
              >
                <span style={{ color: "#EF5DA8" }}>Respond faster</span> and
                more <span style={{ color: "#EF5DA8" }}>empathetically</span> to
                <br />
                your customers
              </div>
              <div
                className="agent-assistant-free-trial-title mobile-view-new-design-display"
                style={{ color: "#fff" }}
              >
                <span style={{ color: "#EF5DA8" }}>Respond faster</span> and
                more <br />
                <span style={{ color: "#EF5DA8" }}>empathetically</span> to your{" "}
                <br />
                customers
              </div>
            </div>
            <div
              className="col-md-2"
              style={{ marginTop: "1%", display: "flex", alignItems: "center" }}
            >
              <div className="d-flex">
                <LearnMoreButton to="/get-a-demo" />
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- End Counts Section --> */}

        {/* <!-- ======= Counts Section ======= --> */}
        <CountsSection theme="dark" />
        {/* <!-- End Counts Section --> */}

        <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="Why is ticket triage process important?"
                  answer="Ticket triaging is a critical process in handling customer queries, support documents, and concerns. It helps companies manage and prioritize a high volume of support tickets according to their urgency and significance. With an efficient ticket triaging process, support teams can speed up their responses to critical issues and improve their overall customer service. In any helpdesk scenario, ensuring that tickets are addressed in a timely and effective manner is crucial, and that’s where ticket triaging comes into play.

Recently, AI has been introduced into ticket triage to automate and streamline the manual triage process. AI helps to triage tickets by using machine learning algorithms to analyze the content of support tickets and automatically assign them a priority level, based on historical knowledge, sentiment, and intent. This automation reduces the manual labor involved in the triage process and can significantly increase ticket resolution speed, leading to higher customer satisfaction rates."
                />
                <Faq
                  id="faq2"
                  question="How does ticket triaging work?"
                  answer="Typically, ticket triaging is a process where support tickets are analyzed, prioritized, and assigned to the right team or agent to ensure the quickest possible resolution of customer requests. It starts with the creation of a support ticket where someone reports an issue. This ticket is then assessed based on predefined factors such as urgency, complexity, and the required skillset for resolution. After this process, known as ticket triaging, the ticket is sent to an appropriate team member or agent for resolution.

Nowadays, AI ticket triage systems are increasingly being used for automating and improving the ticketing automation triaging process. AI ticket triage uses machine learning algorithms to analyze past ticket data and learn patterns related to the assignment and resolution of tickets. This allows a triage system to predict the best possible team or agent for new tickets, thereby reducing the time required for ticket resolution and improving customer satisfaction."
                />
                <Faq
                  id="faq3"
                  question="What criteria are used for ticket categorization?"
                  answer="In a ticketing system and triaging system, tickets are categorized based on several criteria, including priority, type of issue or request, affected system or service, and who is responsible for resolving the issue. These criteria ensure that the appropriate resources are allocated to resolve the issue most efficiently. Ticketing and ticket triaging is critical in managing a large volume of tickets and ensuring the speedy resolution of issues.

The use of AI ticket triage can further enhance this process, by using training data and utilizing machine learning algorithms to automate the categorization and prioritization of tickets. This increases efficiency and minimizes errors that could be made during manual categorization. AI ticket triage can learn from past data and continually improve the accuracy and speed of the ticket triage system, providing significant customer benefits."
                />
                <Faq
                  id="faq4"
                  question="How does ticket triaging impact customer satisfaction?"
                  answer="Ticket triaging plays a significant role in enhancing customer satisfaction by ensuring that service requests are managed efficiently. When tickets are categorized and prioritized effectively, high-priority support issues, such as critical technical problems or urgent customer needs, are addressed promptly, minimizing downtime and frustration. Customers appreciate quick responses and resolutions, which in turn positively impact their satisfaction. Additionally, by triaging tickets, lower-priority tickets are managed in an organized manner, preventing them from overshadowing critical issues. This streamlined approach ensures that resources are allocated where they are needed most, contributing to faster issue resolution times and an overall improved customer experience. Furthermore, ticket triaging allows support teams to identify recurring trends and areas for improvement, leading to better service and long-term customer loyalty."
                />
                <Faq
                  id="faq5"
                  question="Can ticket triaging be automated?"
                  answer="Yes, ticket triaging can be automated using machine learning software like IrisAgent. Automated ticket triage systems can analyze incoming service requests or tickets based on criteria such as issue type, urgency, and keywords. Machine learning algorithms can also be employed to improve the accuracy of ticket categorization and prioritization continually. This automation of ticket ai streamlines the process by swiftly prioritizing tickets, directing high-priority tickets to the appropriate personnel and placing lower-priority tickets in organized queues. By automating ticket triage, organizations can reduce manual workloads, respond to critical issues more quickly, and enhance overall efficiency in customer support or service management operations."
                />
                <Faq
                  id="faq6"
                  question="How is the success of ticket triaging measured?"
                  answer="Success in ticket triaging is typically measured by looking at several key performance indicators. These may include the time taken to respond to a ticket initially, the total time spent on routing tickets, resolving the issue, the number of tickets resolved within the stipulated service level agreement (SLA), and the customers' satisfaction rate. By closely monitoring these metrics, it becomes easier to assess the effectiveness of your ticket triage and ticket automation process."
                />
              </div>
            </div>
          </div>
        </div>
      </section>


        {/* <!-- ======= Contact Services Section ======= --> */}
        {/* <ContactSection /> */}
        {/* <!-- End Contact Section --> */}
      </main>
      {/*  End #main  */}
    </Layout>
  )
}
